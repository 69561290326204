import type { IServiceUsageResponse } from '@experiences/interfaces';
import { roundTo2Decimals } from '@experiences/util';
import { Colors } from '@uipath/apollo-core';
import { Chart } from '@uipath/apollo-lab/react';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import useSWR from 'swr';

import { useConsumableToServicesMap } from '../../../common/constants/Constant';
import {
    getConsumptionData,
    licenseUsageUrl,
} from '../../../services/licensing/LicenseUsageService';
import { UiChartLoading } from '../helperComponents/UiConsumableCard/UiChartLoading';

export const TotalConsumptionGraph: React.FC<{
    consumableCode: string;
    tenantId?: string;
    startDate: number;
    endDate: number;
}> = ({
    consumableCode, tenantId, startDate, endDate,
}) => {
    const { formatMessage: translate } = useIntl();
    const { data: consumptionData } = useSWR(
        {
            url: `${licenseUsageUrl}/services`,
            startDate,
            endDate,
            consumableCode,
            services: useConsumableToServicesMap()[consumableCode],
            aggregationPeriod: 'interval',
            tenantId,
        },
        getConsumptionData,
    );

    const transformedConsumptionData: { services: string[]; usages: number[] } | undefined = useMemo(() => {
        if (!consumptionData) {
            return undefined;
        }

        const services = consumptionData.servicesUsage.map((serviceUsage: IServiceUsageResponse) =>
            translate({ id: `CLIENT_CONSUMABLE_GRAPH_${serviceUsage.serviceName}` })
        );

        const usages = consumptionData.servicesUsage.map((serviceUsage: IServiceUsageResponse) =>
            serviceUsage.usages.reduce((total, usage) => roundTo2Decimals(total + usage.consumedQuantity), 0)
        );

        return {
            services,
            usages,
        };
    }, [ consumptionData, translate ]);

    if (!transformedConsumptionData || !consumptionData?.servicesUsage) {
        return <UiChartLoading
            width={150}
            height={36} />;
    }

    return <Chart
        data={[
            {
                data: transformedConsumptionData.usages,
                renderer: 'bar',
                isCompareData: false,
                color: Colors.ColorBlueSecondary500,
            },
        ]}
        labels={transformedConsumptionData.services}
        legendProperties={{ hasLegend: false }}
        className={`total-consumption-graph-${consumableCode}`}
        maximumBarWidth={40}
        alt={translate({ id: `CLIENT_CONSUMABLE_GRAPH_SHORT_NAME` })}
    />;
};
